exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-gate-tsx": () => import("./../../../src/pages/insights-gate.tsx" /* webpackChunkName: "component---src-pages-insights-gate-tsx" */),
  "component---src-pages-insights-overview-tsx": () => import("./../../../src/pages/insights-overview.tsx" /* webpackChunkName: "component---src-pages-insights-overview-tsx" */),
  "component---src-pages-insights-tsx": () => import("./../../../src/pages/insights.tsx" /* webpackChunkName: "component---src-pages-insights-tsx" */),
  "component---src-pages-product-detail-tsx": () => import("./../../../src/pages/productDetail.tsx" /* webpackChunkName: "component---src-pages-product-detail-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-solution-detail-tsx": () => import("./../../../src/pages/solutionDetail.tsx" /* webpackChunkName: "component---src-pages-solution-detail-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */)
}

